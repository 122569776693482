import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://bfe09e41e9c48044a719c19f773646ee@o4507714165211136.ingest.us.sentry.io/4507714193719296",
  debug: false,
  environment: "prod",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});